import {LoadableComponent} from '@loadable/component'
import React, {useEffect, useState} from 'react'

export const LoadableSuspenseWithPreviousFallback = ({Component}: {Component: LoadableComponent<any>}) => {
  const [LoadedComponent, setLoadedComponent] = useState<LoadableComponent<any>>(null)

  useEffect(() => {
    Component.load().then(() => setLoadedComponent(Component))
  }, [Component])

  if (!LoadedComponent) {
    return null
  }

  return <LoadedComponent />
}
